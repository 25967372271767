import React from "react";
import { HiArrowNarrowRight } from "react-icons/hi";
import { FaGithub, FaLinkedin } from "react-icons/fa";
import { Link } from "react-router-dom";

const Home = (handleClick) => {
  return (
    <div name="home" className="w-full h-screen bg-[#0A192F]">
      {/* container */}
      <div className="max-w-[1000px] mx-auto px-8 flex flex-col justify-center  h-full">
        <p className="text-[#ffbd59]">Hi, my name is</p>
        <h1 className=" text-4xl sm:text-7xl font-bold text-[#ccd6f6]">
          Jacob Stevens
        </h1>
        <h2 className="text-4xl sm:text-7xl font-bold text-[#8892b0]">
          I'm a full stack developer.
        </h2>
        <p className="text-[#8892b0] py-4 max-w-[700px]">
          I'm a full stack developer specializing in building exceptional
          digital experiences. Currently, I'm focused on building responsive
          full-stack web applications
        </p>
        <div className="md:hidden flex justify-between">
          <Link to="/work" smooth={true} duration={500}>
            <button className=" group text-white border-2 px-6 py-3 my-2 flex items-center hover:bg-[#ffbd59]">
              View Work
              <span className="group-hover:rotate-90 duration-300">
                <HiArrowNarrowRight className="ml-3" />
              </span>
            </button>
          </Link>
          <button className="group text-white border-2 px-6 py-3 my-2 flex items-center hover:bg-[#ffbd59]">
            <a
              className="flex justify-between items-center w-full text-gray-300"
              href="https://www.linkedin.com/in/jacob-stevens-133001269/"
              target="_blank"
              rel="noreferrer"
            >
              LinkedIn
              <span className="group-hover:rotate-90 duration-300 pl-1">
                <FaLinkedin size={30} />
              </span>
            </a>
          </button>
          <button className="group text-white border-2 px-6 py-3 my-2 flex items-center hover:bg-[#ffbd59]">
            <a
              className="flex justify-between items-center w-full text-gray-300"
              href="https://github.com/jstevens88"
              target="_blank"
              rel="noreferrer"
            >
              GitHub
              <span className="group-hover:rotate-90 duration-300 pl-1">
                <FaGithub size={30} />
              </span>
            </a>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Home;
