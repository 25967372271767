import React from "react";

function ProvisioInfo() {
  return (
    <div
      name="info"
      className="w-full h-screen bg-[#0a192f] text-gray-300 pt-8"
    >
      <div className="flex flex-col justify-center items-center w-full h-full">
        <div className="max-w-[1000px] w-full grid grid-cols-1 gap-8">
          <div className="sm:text-left pt-8 pl-4">
            <p className="text-4xl font-bold inline border-b-4 border-[#ffbd59] ">
              Crafting Luxury: Provisio Hotel
            </p>
          </div>
          <div>
            <p className="py-4">
              Embark on a journey through the digital realm of elegance and
              sophistication with Provisio Hotel. Our six-month endeavor,
              meticulously crafted by a team of four dedicated individuals,
              brought forth a website that seamlessly mirrors the luxury of the
              fictional Provisio Hotel.
            </p>
            <h1 className="font-bold inline border-b-4 border-[#ffbd59]">
              About the project:
            </h1>
            <p className="py-4">
              In the heart of coding and creativity, we breathed life into
              Provisio Hotel's online presence. With a fusion of Java, HTML,
              CSS, and JavaScript, our team delved into the depths of software
              development, meticulously navigating each step of the cycle. From
              the initial spark of ideas through requirement gathering,
              storyboards, and prototypes, to the final strokes of code that
              shaped the virtual embodiment of Provisio, every detail was
              sculpted with passion and precision.
            </p>
            <h1 className="font-bold inline border-b-4 border-[#ffbd59]">
              Features:
            </h1>
            <p className="py-4">
              <ol className="list-disc">
                <li>
                  Immersive Design: Our website is not just a portal; it's an
                  experience. Immerse yourself in the visually stunning
                  interface, a testament to the careful balance of aesthetics
                  and functionality.
                </li>
                <li>
                  User-Centric Navigation: Navigate with ease through the
                  virtual corridors of Provisio Hotel. Our user-centric design
                  ensures a seamless journey from room selection to reservation.
                </li>
                <li>
                  Under the Hood: Powered by a dynamic blend of Java and
                  JavaScript, the website boasts not only a captivating exterior
                  but also a robust and responsive backend, ensuring a flawless
                  user experience.
                </li>
              </ol>
            </p>
            <h1 className="font-bold inline border-b-4 border-[#ffbd59]">
              Collaboration and Dedication:
            </h1>
            <p className="py-4">
              This project was not a solitary endeavor. The synergy of four
              minds brought diverse perspectives, innovative solutions, and an
              unwavering commitment to excellence. Our collaboration extended
              beyond the code, shaping Provisio's digital identity with passion
              and teamwork.
            </p>
            
          </div>
        </div>
        <div className="max-w-[1000px] w-full grid sm:grid-cols-1 gap-8 px-4">
          <div>
            <a
              href="http://54.183.206.231:8080/provisio/"
              target="_blank"
              rel="noreferrer"
            >
              <button className="text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg">
                Demo
              </button>
            </a>
            <a
              href="https://github.com/jstevens88/provisio"
              target="_blank"
              rel="noreferrer"
            >
              <button className="text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg">
                Code
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProvisioInfo;
